import React from 'react';
import { Squash as Hamburger } from 'hamburger-react';

// eslint-disable-next-line react/prop-types
const MobileMenuHamburger = ({ setMenuOpened, menuOpened }) => {
  return (
    <div className="menu-hamburger-wrapper">
      <Hamburger
        onToggle={() => setMenuOpened(!menuOpened)}
        direction="right"
        rounded
        color={!menuOpened ? '#000' : '#000'}
      />
    </div>
  );
};

export default MobileMenuHamburger;
