export const faq_questions = [
    {
        'id': 0,
        'question': 'Co wziąć ze sobą na pierwszą wizyte?',
        'answer': 'Jeśli posiadasz badania obrazowe np. zdjęcia rentgenowskie, tomografię komputerową, wynik badania USG zabierz je ze sobą. Przyspieszą one proces diagnostyczny i dobór najodpowiedniejszej formy terapii. Jeśli ich nie posiadasz po prostu pojaw się na wizycie. Nasi specjaliści podczas wywiadu, badania przedmiotowego i licznych testów diagnostycznych poradzą sobie bez nich.'
    },
    {
        'id': 1,
        'question': 'Wywiad, co to jest?',
        'answer': 'Badanie podmiotowe zwane również wywiadem to podstawowy element pierwszej wizyty w gabinecie fizjoterpeutycznym. W czasie jego trwania fizjoterapeuta zbiera szereg informacji, które mają na celu poznanie przyczyny dolegliwości pacjenta, kiedy się one pojawaiają, jak długo trwają, jak mocne jest ich nasilenie. Pozwoli to dobrać najodpowiedniejszą formę terapii, która pomoże wyeliminować problem z jakim pacjent zgłosił się do gabinetu. Kolejny etap badania podmiotowego to pytanie o choroby przewlekłe, wcześniejsze urazy, operacje, pracę jaką wykonujemy na codzień czy chociażby powiom stresu. Najważniejsze w Naszej pracy to przede wszystkim nie "szkodzić pacjentowi" dlatego bardzo ważne jest aby przekazane informacje były jak najdokładniejsze.'
    },
    {
        'id': 2,
        'question': 'Testy diagnostyczne',
        'answer': 'Ten etap to ruch lub sekwencja ruchów, które spowodują pojawienie się dolegliwości bólowych lub lekko spotęgują ich oddziaływnie na oragnizm pacjenta. Brzmi groźnie? Nie jest takie w rzeczywistości! W czasie wykonywanie testów jesteśmy w stanie stwierdzić w jakich okolicznościach pojawia się ból i jakie struktury w organizmie są za nie odpowiedzialne.'
    },
    {
        'id': 3,
        'question': 'Zaczynamy terapię..',
        'answer': 'Już od pierwszej wizyty zaczynamy działania terapeutyczne. Na podstawie zebranych informacji Nasi fizjoterapeuci wybierają najlepszą drogę Twojego powrotu do zdrowia i pełnej sprawności. Do wyboru mamy wiele technik (Nasi fizjoterapeuci stale się dokształcają na kursach i szkoleniach) a dzięki nowoczesnym sprzętom możemy uzupełnić terapię o zabiegi fizykalne.'
    },
    {
        'id': 4,
        'question': 'Co jeszcze musisz wiedzieć...',
        'answer': 'Ubierz się w coś wygodnego. W trakcie pierwszej wizyty fizjoterapeuta najpewniej poprosi o wykonanie sekwencji ruchów lub testów o których wspominaliśmy wyżej. Dobrze aby ruchy mogły być wykonywane swobodnie i bez utrudnień. Jedziesz do Nas w drodze z pracy, w garniturze? poradzimy sobie z tym :) W trakcie terapii zawsze konsultujemy odczucia pacjenta. Pytamy o to co czujesz, czy te odczucia są pozytywne, czy zmienić technikę działania.'
    },
]