import Menu from '../Menu/Menu';
import React, { Component } from 'react';
import './Blog.sass';
import { useNavigate } from 'react-router-dom';
import logo_icon from '../../assets/cro720_1.ico';
class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.methods = React.createRef(null);
    this.articles = [
      {
        title: 'Budowanie masy mięśniowej - jak to zrobić?',
        image:
          'https://cdn.searchenginejournal.com/wp-content/uploads/2022/06/image-search-1600-x-840-px-62c6dc4ff1eee-sej-1280x720.png',
        description:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem repellat repellendus, facere delectus alias amet voluptatem fugiat itaque ducimus aspernatur tenetur molestias aperiam, eius veniam temporibus dolorum, error totam cupiditate?',
        createdAt: '20/09/2022 15:23:54',
        category: 'Trening',
      },
      {
        title: 'Lorem Ipsum',
        image:
          'https://cdn.searchenginejournal.com/wp-content/uploads/2022/06/image-search-1600-x-840-px-62c6dc4ff1eee-sej-1280x720.png',
        description:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem repellat repellendus, facere delectus alias amet voluptatem fugiat itaque ducimus aspernatur tenetur molestias aperiam, eius veniam temporibus dolorum, error totam cupiditate?',
        createdAt: '20/09/2022 15:23:54',
      },
      {
        title: 'Lorem Ipsum',
        image:
          'https://cdn.searchenginejournal.com/wp-content/uploads/2022/06/image-search-1600-x-840-px-62c6dc4ff1eee-sej-1280x720.png',
        description:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem repellat repellendus, facere delectus alias amet voluptatem fugiat itaque ducimus aspernatur tenetur molestias aperiam, eius veniam temporibus dolorum, error totam cupiditate?',
        createdAt: '20/09/2022 15:23:54',
      },
      {
        title: 'Lorem Ipsum',
        image:
          'https://cdn.searchenginejournal.com/wp-content/uploads/2022/06/image-search-1600-x-840-px-62c6dc4ff1eee-sej-1280x720.png',
        description:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem repellat repellendus, facere delectus alias amet voluptatem fugiat itaque ducimus aspernatur tenetur molestias aperiam, eius veniam temporibus dolorum, error totam cupiditate?',
        createdAt: '20/09/2022 15:23:54',
      },
      {
        title: 'Lorem Ipsum',
        image:
          'https://cdn.searchenginejournal.com/wp-content/uploads/2022/06/image-search-1600-x-840-px-62c6dc4ff1eee-sej-1280x720.png',
        description:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem repellat repellendus, facere delectus alias amet voluptatem fugiat itaque ducimus aspernatur tenetur molestias aperiam, eius veniam temporibus dolorum, error totam cupiditate?',
        createdAt: '20/09/2022 15:23:54',
      },
      {
        title: 'Lorem Ipsum',
        image:
          'https://cdn.searchenginejournal.com/wp-content/uploads/2022/06/image-search-1600-x-840-px-62c6dc4ff1eee-sej-1280x720.png',
        description:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem repellat repellendus, facere delectus alias amet voluptatem fugiat itaque ducimus aspernatur tenetur molestias aperiam, eius veniam temporibus dolorum, error totam cupiditate?',
        createdAt: '20/09/2022 15:23:54',
      },
      {
        title: 'Lorem Ipsum',
        image:
          'https://cdn.searchenginejournal.com/wp-content/uploads/2022/06/image-search-1600-x-840-px-62c6dc4ff1eee-sej-1280x720.png',
        description:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem repellat repellendus, facere delectus alias amet voluptatem fugiat itaque ducimus aspernatur tenetur molestias aperiam, eius veniam temporibus dolorum, error totam cupiditate?',
        createdAt: '20/09/2022 15:23:54',
      },
      {
        title: 'Lorem Ipsum',
        image:
          'https://cdn.searchenginejournal.com/wp-content/uploads/2022/06/image-search-1600-x-840-px-62c6dc4ff1eee-sej-1280x720.png',
        description:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem repellat repellendus, facere delectus alias amet voluptatem fugiat itaque ducimus aspernatur tenetur molestias aperiam, eius veniam temporibus dolorum, error totam cupiditate?',
        createdAt: '20/09/2022 15:23:54',
      },
    ];
  }
  render() {
    return (
      <div className="blog-wrapper">
        <Menu methods={this.methods} />
        <div className="main-wrapper">
          <h1>Blog</h1>
          <div className="lastest-article-wrapper">
            <div className="lastest-article">
              <div id="bandge-lastest">Ostatni wpis</div>
              <div style={{ position: 'relative' }}>
                <div id="background-image-article-opacity-color"></div>
                <img
                  id="lastest-article-image"
                  src={this.articles[0].image}
                  alt={this.articles[0].title}
                />
              </div>
              {/* <p id="article-date-p">{this.articles[0].createdAt}</p> */}
              <p className="blog_category">
                {this.articles[0].category.toUpperCase()}
              </p>
              <h2>{this.articles[0].title}</h2>
              <p className="desc">
                {this.articles[0].description.slice(0, 100) + '...'}
              </p>
              <div id="author">
                <div id="blog_avatar">
                  <img
                    src={logo_icon}
                    width={'50px'}
                    alt={'Logo cromed swarzędz'}
                  />
                </div>

                <p className="author_name">
                  Cro-med <br /> {this.articles[0].createdAt.split(' ')[0]}
                </p>
                <div className="see-more">
                  <button>Zobacz</button>
                </div>
              </div>
            </div>
            <div className="three-lastest-items">
              {this.articles.slice(1, 4).map((data) => {
                return (
                  <div className="lastest-item" key={data.title}>
                    <div
                      className="lastest-item-image"
                      style={{
                        background: `url(${data.image})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                      }}
                    ></div>
                    <div className="lastest-item-content">
                      <h1>{data.title}</h1>
                      <p>{data.description.slice(0, 50)}...</p>
                      <span className="article-date">{data.createdAt}</span>
                    </div>
                  </div>
                );
              })}
              <div className="lastest-item"></div>
              <div className="lastest-item"></div>
              <div className="lastest-item"></div>
            </div>
          </div>
          <div className="articles-wrapper">
            {this.articles.map((article, index) => (
              <ArticleLink key={index} title={article.title}>
                <div className="article-image">
                  <img src={article.image} alt="" />
                </div>
                <div className="article-content">
                  <h1>{article.title}</h1>
                  <p>{article.description}</p>
                </div>
                <span className="article-date">{article.createdAt}</span>
              </ArticleLink>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

// eslint-disable-next-line react/prop-types
const ArticleLink = ({ children, index, title }) => {
  let navigate = useNavigate();

  const handleClick = (input) => {
    const inputLower = input.toLowerCase();
    const inputArray = inputLower.split(/ +/);
    const title = inputArray.join('-');
    navigate(`/blog/${title}`);
  };

  return (
    <div className="article" key={index} onClick={() => handleClick(title)}>
      {children}
    </div>
  );
};
export default Blog;
