import React from 'react';

import './Promotion.sass';

import reklama from '../../assets/mobile-main.webp';
import { useCookies } from 'react-cookie';

function Promotion(props) {
  const [, setCookies] = useCookies();
  return (
    <>
      {/* eslint-disable-next-line react/prop-types */}(
      {props.show ? (
        // eslint-disable-next-line react/prop-types
        <div ref={props.refs}>
          <div className="background-promotion"></div> {/* z-index:999 */}
          <div className="promotion-container">
            <div
              className="promotion-photo"
              style={{
                backgroundImage: `url(${reklama})`,
                boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,.4)',
              }}
            ></div>

            <div className="promotion-title">
              <h2>
                {/*🌷 WIOSENNA PROMOCJA 🌷*/}
                PRZERWA W CROMED
              </h2>{' '}
              {/* eslint-disable-next-line react/prop-types */}
              <p onClick={() => props.hideModal()}>
                <svg
                  width="31"
                  height="31"
                  viewBox="0 0 31 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.3788 0.625L15.5 12.5038L3.62125 0.625L0.625 3.62125L12.5038 15.5L0.625 27.3788L3.62125 30.375L15.5 18.4962L27.3788 30.375L30.375 27.3788L18.4962 15.5L30.375 3.62125L27.3788 0.625Z"
                    fill="white"
                  />
                </svg>
                Zamknij
              </p>
            </div>
            <div className="promotion-ofert">
              <p>
                Trwa przerwa w związku ze zmianą lokalizacji. <br />
                Zapraszamy od <b>20 Sierpnia </b> do nowego lokalu na{' '}
                <b>ul. Raczyńskiego 2/23</b>
              </p>
              {/*<svg*/}
              {/*    width="15"*/}
              {/*    height="15"*/}
              {/*    className="card-list-svg-promotion"*/}
              {/*    viewBox="0 0 9 8"*/}
              {/*    fill="none"*/}
              {/*    xmlns="http://www.w3.org/2000/svg"*/}
              {/*>*/}
              {/*    <path*/}
              {/*        d="M7.48154 0.147145C7.35719 0.0726257 7.22041 0.025236 7.07904 0.00768613C6.93767 -0.00986375 6.79447 0.00277033 6.65763 0.044866C6.5208 0.0869617 6.39301 0.157693 6.28157 0.253016C6.17014 0.348338 6.07725 0.466381 6.00821 0.600395L3.997 4.49823L2.84596 3.25865C2.74603 3.14722 2.62649 3.05834 2.49432 2.99719C2.36214 2.93605 2.21999 2.90387 2.07615 2.90252C1.9323 2.90117 1.78965 2.93069 1.65651 2.98935C1.52337 3.04801 1.40242 3.13464 1.3007 3.24418C1.19898 3.35373 1.11854 3.48399 1.06407 3.62737C1.0096 3.77074 0.982189 3.92437 0.983439 4.07928C0.984689 4.23419 1.01457 4.38728 1.07135 4.52962C1.12813 4.67196 1.21066 4.80069 1.31413 4.90831L3.48079 7.24165C3.68554 7.46273 3.96179 7.58406 4.24671 7.58406L4.39675 7.5724C4.5628 7.54738 4.72119 7.4812 4.85945 7.37907C4.99772 7.27693 5.11208 7.14163 5.19354 6.98381L7.90188 1.73381C7.97102 1.59991 8.015 1.45265 8.0313 1.30044C8.0476 1.14823 8.03589 0.99406 7.99686 0.846723C7.95783 0.699386 7.89223 0.561771 7.80381 0.441737C7.71539 0.321703 7.60589 0.2216 7.48154 0.147145Z"*/}
              {/*        fill="#5FD7F1"*/}
              {/*    />*/}
              {/*</svg>Kobido + masaż relaksacyjny  279 zł  (zamiast 330),<br />*/}
              {/*<svg*/}
              {/*    width="9"*/}
              {/*    height="8"*/}
              {/*    className="card-list-svg-promotion"*/}
              {/*    viewBox="0 0 9 8"*/}
              {/*    fill="none"*/}
              {/*    xmlns="http://www.w3.org/2000/svg"*/}
              {/*>*/}
              {/*    <path*/}
              {/*        d="M7.48154 0.147145C7.35719 0.0726257 7.22041 0.025236 7.07904 0.00768613C6.93767 -0.00986375 6.79447 0.00277033 6.65763 0.044866C6.5208 0.0869617 6.39301 0.157693 6.28157 0.253016C6.17014 0.348338 6.07725 0.466381 6.00821 0.600395L3.997 4.49823L2.84596 3.25865C2.74603 3.14722 2.62649 3.05834 2.49432 2.99719C2.36214 2.93605 2.21999 2.90387 2.07615 2.90252C1.9323 2.90117 1.78965 2.93069 1.65651 2.98935C1.52337 3.04801 1.40242 3.13464 1.3007 3.24418C1.19898 3.35373 1.11854 3.48399 1.06407 3.62737C1.0096 3.77074 0.982189 3.92437 0.983439 4.07928C0.984689 4.23419 1.01457 4.38728 1.07135 4.52962C1.12813 4.67196 1.21066 4.80069 1.31413 4.90831L3.48079 7.24165C3.68554 7.46273 3.96179 7.58406 4.24671 7.58406L4.39675 7.5724C4.5628 7.54738 4.72119 7.4812 4.85945 7.37907C4.99772 7.27693 5.11208 7.14163 5.19354 6.98381L7.90188 1.73381C7.97102 1.59991 8.015 1.45265 8.0313 1.30044C8.0476 1.14823 8.03589 0.99406 7.99686 0.846723C7.95783 0.699386 7.89223 0.561771 7.80381 0.441737C7.71539 0.321703 7.60589 0.2216 7.48154 0.147145Z"*/}
              {/*        fill="#5FD7F1"*/}
              {/*    />*/}
              {/*</svg>3 x Kobido 449 zł (zamiast 600),<br />*/}
              {/*<svg*/}
              {/*    width="9"*/}
              {/*    height="8"*/}
              {/*    className="card-list-svg-promotion"*/}
              {/*    viewBox="0 0 9 8"*/}
              {/*    fill="none"*/}
              {/*    xmlns="http://www.w3.org/2000/svg"*/}
              {/*>*/}
              {/*    <path*/}
              {/*        d="M7.48154 0.147145C7.35719 0.0726257 7.22041 0.025236 7.07904 0.00768613C6.93767 -0.00986375 6.79447 0.00277033 6.65763 0.044866C6.5208 0.0869617 6.39301 0.157693 6.28157 0.253016C6.17014 0.348338 6.07725 0.466381 6.00821 0.600395L3.997 4.49823L2.84596 3.25865C2.74603 3.14722 2.62649 3.05834 2.49432 2.99719C2.36214 2.93605 2.21999 2.90387 2.07615 2.90252C1.9323 2.90117 1.78965 2.93069 1.65651 2.98935C1.52337 3.04801 1.40242 3.13464 1.3007 3.24418C1.19898 3.35373 1.11854 3.48399 1.06407 3.62737C1.0096 3.77074 0.982189 3.92437 0.983439 4.07928C0.984689 4.23419 1.01457 4.38728 1.07135 4.52962C1.12813 4.67196 1.21066 4.80069 1.31413 4.90831L3.48079 7.24165C3.68554 7.46273 3.96179 7.58406 4.24671 7.58406L4.39675 7.5724C4.5628 7.54738 4.72119 7.4812 4.85945 7.37907C4.99772 7.27693 5.11208 7.14163 5.19354 6.98381L7.90188 1.73381C7.97102 1.59991 8.015 1.45265 8.0313 1.30044C8.0476 1.14823 8.03589 0.99406 7.99686 0.846723C7.95783 0.699386 7.89223 0.561771 7.80381 0.441737C7.71539 0.321703 7.60589 0.2216 7.48154 0.147145Z"*/}
              {/*        fill="#5FD7F1"*/}
              {/*    />*/}
              {/*</svg>Masaż antycellulitowy + bański chińskie (dwie okolice) 189 zł (zamiast 240),<br />*/}
              {/*<svg*/}
              {/*    width="9"*/}
              {/*    height="8"*/}
              {/*    className="card-list-svg-promotion"*/}
              {/*    viewBox="0 0 9 8"*/}
              {/*    fill="none"*/}
              {/*    xmlns="http://www.w3.org/2000/svg"*/}
              {/*>*/}
              {/*    <path*/}
              {/*        d="M7.48154 0.147145C7.35719 0.0726257 7.22041 0.025236 7.07904 0.00768613C6.93767 -0.00986375 6.79447 0.00277033 6.65763 0.044866C6.5208 0.0869617 6.39301 0.157693 6.28157 0.253016C6.17014 0.348338 6.07725 0.466381 6.00821 0.600395L3.997 4.49823L2.84596 3.25865C2.74603 3.14722 2.62649 3.05834 2.49432 2.99719C2.36214 2.93605 2.21999 2.90387 2.07615 2.90252C1.9323 2.90117 1.78965 2.93069 1.65651 2.98935C1.52337 3.04801 1.40242 3.13464 1.3007 3.24418C1.19898 3.35373 1.11854 3.48399 1.06407 3.62737C1.0096 3.77074 0.982189 3.92437 0.983439 4.07928C0.984689 4.23419 1.01457 4.38728 1.07135 4.52962C1.12813 4.67196 1.21066 4.80069 1.31413 4.90831L3.48079 7.24165C3.68554 7.46273 3.96179 7.58406 4.24671 7.58406L4.39675 7.5724C4.5628 7.54738 4.72119 7.4812 4.85945 7.37907C4.99772 7.27693 5.11208 7.14163 5.19354 6.98381L7.90188 1.73381C7.97102 1.59991 8.015 1.45265 8.0313 1.30044C8.0476 1.14823 8.03589 0.99406 7.99686 0.846723C7.95783 0.699386 7.89223 0.561771 7.80381 0.441737C7.71539 0.321703 7.60589 0.2216 7.48154 0.147145Z"*/}
              {/*        fill="#5FD7F1"*/}
              {/*    />*/}
              {/*</svg>Masaż leczniczy + masaż relaksacyjny 199 zł (zamiast 250).*/}
            </div>
            <div className="promotion-footer">
              <div
                className="promotion-check"
                onClick={() => {
                  setCookies('promotionShowed', true, {
                    expires: new Date(Date.now() + 3600 * 1000 * 24),
                    path: '/',
                  });
                  window.location.href =
                    'https://goo.gl/maps/cGnqnCcv6P1hXDtH6';
                }}
              >
                Nowa lokalizacja!
              </div>
              <div
                className="promotion-quit"
                onClick={() => {
                  setCookies('promotionShowed', true, {
                    expires: new Date(Date.now() + 3600 * 1000 * 24),
                    path: '/',
                  });
                  // eslint-disable-next-line react/prop-types
                  props.hideModal();
                }}
              >
                Przejdź do strony
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
export default Promotion;
