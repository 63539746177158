import React, {useState, useEffect} from 'react';
import Menu from '../Menu/Menu'
const Diagnostic = () => {
    const [mobile, setMobile] = useState(window.innerWidth > 1200 ? false : true)
    useEffect(() => {
        window.addEventListener('resize', handleResize)
    }, [])
    const handleResize = () => {

        setMobile(window.innerWidth > 1200 ? false : true)
    }
    return (
        <>
            <Menu/>
            {mobile ? (<div className="mobile-header-wrapper">
              <div className="mobile-header-wrapper-content">
                <div className="mobile-header-wrapper-content-background"></div>
                <h1>Diagnostyka</h1>
                {/* <p>Podstawowa Opieka Zdrowotna NFZ</p> */}
              </div>
            </div> ) : (<div className="header-wrapper" style={{height: '500px'}}>
            <svg
                className="header-wrapper-svg"
                width="1920"
                height="527"
                viewBox="0 0 1920 527"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1323.5 520.677C699.9 543.947 83.3333 497.357 0 472.074V0.782305H1922V472.074C1917.5 473.179 1947.1 497.406 1323.5 520.677Z"
                  fill="url(#paint0_linear)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="-4.31637e-06"
                    y1="230.818"
                    x2="1921.88"
                    y2="207.635"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop
                      offset="0.0574296"
                      stopColor="#243959"
                      stopOpacity="0.92"
                    />
                    <stop
                      offset="0.878291"
                      stopColor="#1A9AE2"
                      stopOpacity="0.58"
                    />
                  </linearGradient>
                </defs>
              </svg>
              <h1 className="header-wrapper-content-title" id="privacy-header">Diagnostyka</h1>
              {/* <p className="header-wrapper-content-title-sub">
                Skontaktuj się z nami!
              </p> */}
            </div>)}
            <div className="mobile-poz-content">
            <h1 id="lab">Informacje</h1>
                    <p id="lab_p"><strong>Czynne od 3 Listopada 2022r.</strong></p>
                    
                    <h3>Godziny przyjęć:</h3>
                    <p>Poniedziałek - piątek: 8-11</p>
                    <a href="https://wyniki.diag.pl"><p>Wyniki online</p></a></div>
            </>
            
    )
}

export default Diagnostic