import React from 'react';
import { Link } from 'react-router-dom';
import cromed from '../../../../assets/cro-med.png'
// eslint-disable-next-line react/prop-types
const MobileMenuContent = ({ menuOpened }) => {
  return (
    <div
      className="menu-mobile-content"
      style={
        menuOpened
          ? {
              transform: 'translateX(0px)',
              zIndex: 99999,
              transition: '.5s all ease-in-out',
              marginTop: '-91px',
            }
          : {
              transform: 'translateX(200%)',
              zIndex: -99999,
              transition: '.5s all ease-in-out',
              marginTop: '-91px',
            }
      }
    >
      <div>
        <img src={cromed}/>
        <Link to={'/poz'}>
          {' '}
          <p>Lekarz Rodzinny</p>
        </Link>
        <Link to={'/diagnostyka'}>
          {' '}
          <p>Diagnostyka</p>
        </Link>
        <a href="https://wyniki.diag.pl">
          {' '}
          <p>Wyniki online</p>
        </a>
        <Link to={'/offert'}>
          <p>Fizjoterapia</p>
        </Link>
        <Link to={'/contact'}>
          {' '}
          <p>Kontakt</p>
        </Link>
        <Link to={'/privacy'}>
          {' '}
          <p>Polityka prywatności</p>
        </Link>
      </div>
    </div>
  );
};

export default MobileMenuContent;
