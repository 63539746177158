import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../../assets/cro-med.png';

const MenuLogo = () => {
  return (
    <div className="menu-logo-wrapper">
      <Link to={'/'} className="img-wrapper">
        <img src={logo} alt="logo" />
      </Link>
    </div>
  );
};

export default MenuLogo;
