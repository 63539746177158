import React from 'react';
import './FAQ.sass';
import { faq_questions } from './faq.js';
import gsap, { Power4 } from 'gsap';

export default class FAQ extends React.Component {
  constructor() {
    super();
    this.state = {
      question: -1,
      height: '',
      open: false,
    };

    this.handleShowAnswer = this.handleShowAnswer.bind(this);

    this.questionWrapper = React.createRef(null);
    this.question = React.createRef(null);
  }

  handleShowAnswer(data) {
    console.log(data, this.state.question, this.state.question === data);
    // if (this.state.question === data) {
    console.log('ale pizda ogien', this.question.current[data]);
    // this.setState({
    //   open: !this.state.open,
    // });
    // } else {
    console.log({ question: this.questionWrapper });
    if (this.state.question === data) {
      if (this.state.open) {
        gsap.to(`.question-nr-${data}`, {
          minHeight: '100px',
          height: '100px',
          duration: 0.5,
          ease: Power4.easeOut,
        });

        return this.setState({ open: false, question: data });
      } else {
        gsap.to(`.question-nr-${data}`, {
          minHeight: '300px',
          height: 'auto',
          duration: 0.5,
          ease: Power4.easeOut,
        });
        return this.setState({ open: true, question: data });
      }
    } else {
      if (this.state.open) {
        gsap.to(`.question-nr-${this.state.question}`, {
          minHeight: '100px',
          height: '100px',
          duration: 0.5,
          ease: Power4.easeOut,
        });

        gsap.to(`.question-nr-${data}`, {
          minHeight: '300px',
          height: 'auto',
          duration: 0.5,
          ease: Power4.easeOut,
        });

        return this.setState({ open: true, question: data });
      } else {
        gsap.to(`.question-nr-${data}`, {
          minHeight: '300px',
          height: 'auto',
          duration: 0.5,
          ease: Power4.easeOut,
        });
        return this.setState({ open: true, question: data });
      }
    }
    // anime
    //   .timeline({
    //     easing: "easeInOutSine",
    //     targets: this.question.current[data],
    //     duration: 500,
    //     endDelay: 400,
    //   })
    //   .add({
    //     duration: 300,
    //     opacity: [1, 0],
    //   });
    // }
  }

  render() {
    return (
      <>
        <div className="faq-wrapper">
          {/* <div className="faq-header-wrapper">
                    <div className="faq-header">
                        <div className="faq-header-top">
                            FAQ’s
                        </div>
                        <div className="faq-header-bottom">
                            collections of frequently asked questions and answers
                        </div>
                    </div>
                </div> */}
          <div className="faq-questions-wrapper">
            <div className="faq-questions">
              {faq_questions.map((data, i) => {
                return (
                  <div
                    key={i}
                    className={`faq-question-wrapper question-nr-${i}`}
                    ref={this.questionWrapper}
                    onClick={() => this.handleShowAnswer(data.id)}
                    style={
                      this.state.question === data.id
                        ? this.state.open
                          ? {
                              borderBottom: '10px solid #E66D76',
                              boxShadow: '0px 0px 71px rgba(0, 0, 0, 0.08)',
                              transition: '.2s min-height linear',
                            }
                          : {
                              minHeight: '100px',
                              transition: '.2s min-height linear',
                            }
                        : {
                            minHeight: '100px',
                            transition: '.2s min-height linear',
                          }
                    }
                  >
                    <div className="faq-question" ref={this.question}>
                      <div className="faq-question-header">
                        <div
                          className="faq-question-header-text"
                          style={
                            this.state.question === data.id
                              ? this.state.open
                                ? { color: '#E66D76', fontWeight: '600' }
                                : {
                                    color: 'rgba(42, 63, 94, 0.71)',
                                    fontWeight: '400',
                                  }
                              : {
                                  color: 'rgba(42, 63, 94, 0.71)',
                                  fontWeight: '400',
                                }
                          }
                        >
                          {data.id + 1}. {data.question}
                        </div>
                        <div className="faq-question-header-arrow">
                          <p
                            style={
                              this.state.question === data.id
                                ? this.state.open
                                  ? {
                                      color: '#E66D76',
                                      transform:
                                        'rotate(-90deg) translate(-50%, -50%)',
                                      right: '-47px',
                                    }
                                  : {
                                      color: 'rgba(141, 143, 145, 0.62)',
                                      transform:
                                        'rotate(90deg) translate(-50%, -50%)',
                                    }
                                : {
                                    color: 'rgba(141, 143, 145, 0.62)',
                                    transform:
                                      'rotate(90deg) translate(-50%, -50%)',
                                  }
                            }
                          >
                            &gt;
                          </p>
                        </div>
                      </div>
                      <div className="faq-question-content">
                        {/* <p>Our standard delivery (UK Mainland) is just £3.25. Orders over £300 qualify for free standard UK mainland delivery. The Next Working Day delivery cost is £9.75. Delivery to other areas of the UK will cost extra. Please contact us for the correct delivery cost.</p> */}
                        {data.answer}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
}
