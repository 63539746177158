import React from 'react';
import './Footer.sass';
import { Link } from 'react-router-dom';
import Emitter from '../services/emitter';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      someKey: 'someValue',
    };

    this.handleResize = this.handleResize.bind(this);
  }
  componentDidMount() {
    this.setState({
      mobile: window.innerWidth > 1200 ? false : true,
    });
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  handleResize() {
    this.setState({
      mobile: window.innerWidth > 1200 ? false : true,
    });
  }

  render() {
    return (
      <>
        {!this.state.mobile ? (
          <>
            <div className="footer-wrapper">
              <div className="footer-top">
                <svg
                  id="footer-svg1"
                  width="100%"
                  height="100%"
                  viewBox="0 0 1923 491"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <clipPath id="footer">
                    <path d="M1922.24 0C1208.14 220.248 226.97 65.6355 0 19.4926V490.785H1922.24V0Z" />
                  </clipPath>
                  <image
                    clipPath="url(#footer)"
                    x="0"
                    y="-400"
                    xlinkHref="./graphics/iStock-1223737139.jpg"
                  ></image>
                </svg>
                <svg
                  id="footer-svg2"
                  width="100%"
                  height="100%"
                  viewBox="0 0 1923 491"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <path
                    d="M1922.24 0C1208.14 220.248 226.97 65.6355 0 19.4926V490.785H1922.24V0Z"
                    fill="rgba(10, 67, 100, 0.58)"
                  />
                </svg>
                <div className="footer-top-content-wrapper">
                  <div className="footer-top-content">
                    <div className="footer-top-logo-wrapper">
                      <span>cromed</span>
                    </div>
                    <div className="footer-top-section-wrapper">
                      <p className="footer-top-section-header">Strona główna</p>
                      <p className="footer-top-section-item">
                        <Link push to="/offert">
                          Umów sie
                        </Link>
                      </p>
                      <p className="footer-top-section-item">
                        <Link push to="/#charlie-row">
                          Joga
                        </Link>
                      </p>
                    </div>
                    <div className="footer-top-section-wrapper">
                      <p className="footer-top-section-header">O nas</p>
                      <p className="footer-top-section-item">
                        <Link push to="/about">
                          FAQ
                        </Link>
                      </p>
                      <p
                        className="footer-top-section-item"
                        onClick={() => {
                          window.location.href = '/pracownicy';
                        }}
                      >
                        Nasz zespół
                      </p>
                    </div>
                    <div className="footer-top-section-wrapper">
                      <p className="footer-top-section-header">Oferta</p>
                      <p
                        className="footer-top-section-item"
                        onClick={() => {
                          if (window.location.pathname === '/') {
                            // eslint-disable-next-line react/prop-types
                            this.props.solutions.current.scrollIntoView({
                              behavior: 'smooth',
                              block: 'center',
                              inline: 'nearest',
                            });
                          } else {
                            window.location.href = '/#solutions';
                          }
                        }}
                      >
                        Rozwiązania fizjoterapii
                      </p>
                      <p
                        className="footer-top-section-item"
                        onClick={() => {
                          if (window.location.pathname === '/') {
                            // eslint-disable-next-line react/prop-types
                            this.props.methods.current.scrollIntoView({
                              behavior: 'smooth',
                              block: 'center',
                              inline: 'nearest',
                            });
                          } else {
                            window.location.href = '/#methods';
                          }
                        }}
                      >
                        Metody pracy
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-bottom">
                <div className="footer-bottom-left">
                  <p>Copyright ⓒ 2021 Cromed All Rights Reserved</p>
                </div>
                <div className="footer-bottom-right">
                  <div className="footer-bottom-right-icons">
                    <div className="footer-bottom-right-icon">
                      <svg
                        width="36"
                        height="37"
                        viewBox="0 0 36 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.876 0.781006C8.03449 0.781006 0.0546875 8.76081 0.0546875 18.6023C0.0546875 28.4438 8.03449 36.4236 17.876 36.4236C27.7175 36.4236 35.6973 28.4438 35.6973 18.6023C35.6973 8.76081 27.7175 0.781006 17.876 0.781006ZM26.4405 14.2146C26.4525 14.4016 26.4525 14.5965 26.4525 14.7874C26.4525 20.6271 22.0051 27.3538 13.8781 27.3538C11.372 27.3538 9.04887 26.6259 7.09171 25.3728C7.44973 25.4126 7.79183 25.4285 8.15781 25.4285C10.2263 25.4285 12.1278 24.7284 13.6434 23.5429C11.7022 23.5031 10.0712 22.2302 9.51429 20.4799C10.1945 20.5793 10.8071 20.5793 11.5073 20.4003C10.5077 20.1973 9.60927 19.6544 8.9646 18.864C8.31994 18.0736 7.9688 17.0843 7.97084 16.0644V16.0087C8.5556 16.3388 9.24379 16.5417 9.9638 16.5696C9.35852 16.1662 8.86214 15.6197 8.51865 14.9785C8.17517 14.3373 7.9952 13.6213 7.99471 12.8939C7.99471 12.0705 8.20952 11.3186 8.59538 10.6663C9.70485 12.032 11.0893 13.1491 12.6587 13.9448C14.2282 14.7404 15.9475 15.197 17.7049 15.2847C17.0804 12.2813 19.324 9.85077 22.021 9.85077C23.294 9.85077 24.4396 10.3838 25.2471 11.2431C26.2456 11.0561 27.2003 10.6822 28.0516 10.1809C27.7214 11.2033 27.0293 12.0665 26.1104 12.6115C27.0014 12.516 27.8607 12.2694 28.6563 11.9233C28.0556 12.8064 27.3038 13.5901 26.4405 14.2146Z"
                          fill="#68778D"
                        />
                      </svg>
                    </div>
                    <div className="footer-bottom-right-icon">
                      <svg
                        width="35"
                        height="35"
                        viewBox="0 0 35 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0.765625 17.7542C0.765625 26.1458 6.86032 33.1238 14.8314 34.5388V22.348H10.6117V17.6599H14.8314V13.9086C14.8314 9.68885 17.5503 7.3455 21.3959 7.3455C22.614 7.3455 23.9277 7.53257 25.1458 7.71965V12.0336H22.9895C20.9261 12.0336 20.4577 13.0646 20.4577 14.3784V17.6599H24.9587L24.209 22.348H20.4577V34.5388C28.4288 33.1238 34.5234 26.1472 34.5234 17.7542C34.5234 8.41871 26.9279 0.781006 17.6445 0.781006C8.36114 0.781006 0.765625 8.41871 0.765625 17.7542Z"
                          fill="#2A3F5E"
                          fillOpacity="0.71"
                        />
                      </svg>
                    </div>
                    <div className="footer-bottom-right-icon">
                      <svg
                        width="35"
                        height="35"
                        viewBox="0 0 35 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M17.741 0.236572C8.26784 0.236572 0.589844 7.916 0.589844 17.3877C0.589844 26.8594 8.26784 34.5389 17.741 34.5389C27.2127 34.5389 34.8922 26.8594 34.8922 17.3877C34.8922 7.916 27.2127 0.236572 17.741 0.236572ZM17.9411 27.4183C12.4127 27.4183 7.93625 22.9304 7.93625 17.3877C7.93625 11.845 12.4127 7.35716 17.9411 7.35716C20.6424 7.35716 22.9006 8.35336 24.6329 9.97128L21.8115 12.7984V12.7926C20.761 11.7893 19.429 11.2748 17.9411 11.2748C14.6395 11.2748 11.9568 14.0704 11.9568 17.382C11.9568 20.6908 14.6395 23.495 17.9411 23.495C20.9368 23.495 22.975 21.777 23.3952 19.4187H17.9411V15.5068H27.3528C27.4786 16.1786 27.5457 16.8789 27.5457 17.6135C27.5457 23.3449 23.7196 27.4183 17.9411 27.4183Z"
                          fill="#2A3F5E"
                          fillOpacity="0.71"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="footer-mobile-wrapper">
              <div className="footer-mobile-background">
                <svg
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 375 771"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.25"
                    d="M74.1638 42.9378C4.51541 55.523 -37.946 65.5 -37.946 65.5L-37.9473 77.8382V233.33H459.91V0.979492C400.33 0.979106 416.62 43.6103 322.352 22.3313C272.75 11.1348 244.163 43.9933 208.263 49.0214C175.368 53.6288 129.883 32.8696 74.1638 42.9378Z"
                    fill="#5AA6FE"
                    stroke="#5AA6FE"
                  />
                  <path
                    opacity="0.25"
                    d="M-33.0438 52.0002C-109.157 64.6489 -155.559 74.6763 -155.559 74.6763L-155.561 87.0768V243.354H388.505V9.83008C323.396 9.82969 341.197 52.6761 238.18 31.2897C183.974 20.0367 152.734 53.061 113.502 58.1145C77.5535 62.7452 27.8466 41.8812 -33.0438 52.0002Z"
                    fill="#5AA6FE"
                    stroke="#5AA6FE"
                  />
                  <path
                    d="M52.9367 75.7986C0.46875 83.5429 -0.0488281 84.6328 -0.0488281 84.6328L-0.048275 97.2747V769.622H375V49.9795C330.118 49.9793 342.389 76.2125 271.374 63.1184C234.008 56.2286 182.031 72.7045 154.987 75.7986C130.206 78.6338 94.911 69.6032 52.9367 75.7986Z"
                    fill="#5AA6FE"
                    stroke="#5AA6FE"
                  />
                </svg>
              </div>
              <div className="footer-mobile-content-wrapper">
                <div className="footer-mobile-content">
                  <div className="footer-mobile-content-header-wrapper">
                    <span>cromed</span>
                  </div>
                  <div className="footer-mobile-sections">
                    <div className="footer-mobile-section">
                      <p className="footer-mobile-section-title">
                        Strona główna
                      </p>
                      <p className="footer-mobile-section-item">
                        <Link
                          onClick={() => {
                            window.location.href =
                              'https://booksy.com/pl-pl/108831_cromed-centrum-fizjoterapii_fizjoterapia_16974_swarzedz#ba_s=sh_1';
                          }}
                          to="/"
                        >
                          Umów się
                        </Link>
                      </p>
                      <p className="footer-mobile-section-item">
                        <Link
                          onClick={() => {
                            Emitter.emit('SHOW_YOGA');
                            setTimeout(() => {
                              Emitter.emit('SHOW_YOGA');
                            }, 200);
                          }}
                          to={'/'}
                        >
                          Joga
                        </Link>
                      </p>
                      <p className="footer-mobile-section-item">
                        <Link push to="/contact">
                          Kontakt
                        </Link>
                      </p>
                    </div>
                    <div className="footer-mobile-section">
                      <p className="footer-mobile-section-title">O nas</p>

                      <p
                        className="footer-mobile-section-item"
                        onClick={() => {
                          //   if (window.location.pathname === "/") {
                          //     this.props.employee.current.scrollIntoView({
                          //       behavior: "smooth",
                          //       block: "center",
                          //       inline: "nearest",
                          //     });
                          //   } else {
                          //     window.location.href = "/#employee";
                          //   }
                        }}
                      >
                        <Link push to="/team">
                          Nasza Kadra
                        </Link>
                      </p>
                    </div>
                    <div className="footer-mobile-section">
                      <p className="footer-mobile-section-title">Oferta</p>
                      <p
                        className="footer-mobile-section-item"
                        onClick={() => {
                          if (window.location.pathname === '/') {
                            // eslint-disable-next-line react/prop-types
                            this.props.solutions.current.scrollIntoView({
                              behavior: 'smooth',
                              block: 'center',
                              inline: 'nearest',
                            });
                          } else {
                            window.location.href = '/#solutions';
                          }
                        }}
                      >
                        Rozwiązania fizjoterapii
                      </p>
                      <p
                        className="footer-mobile-section-item"
                        onClick={() => {
                          if (window.location.pathname === '/') {
                            // eslint-disable-next-line react/prop-types
                            this.props.methods.current.scrollIntoView({
                              behavior: 'smooth',
                              block: 'center',
                              inline: 'nearest',
                            });
                          } else {
                            window.location.href = '/#methods';
                          }
                        }}
                      >
                        Metody pracy
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-mobile-bottom">
                <div className="footer-mobile-bottom-content">
                  <div className="footer-mobile-bottom-copyright">
                    Copyright ⓒ 2021 Cromed All Rights Reserved
                  </div>
                  <div className="footer-mobile-bottom-icons">
                    <div className="footer-mobile-bottom-icon">
                      <svg
                        width="23"
                        height="23"
                        viewBox="0 0 23 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.4704 0.562988C5.39384 0.562988 0.466797 5.49003 0.466797 11.5666C0.466797 17.6431 5.39384 22.5701 11.4704 22.5701C17.5469 22.5701 22.4739 17.6431 22.4739 11.5666C22.4739 5.49003 17.5469 0.562988 11.4704 0.562988ZM16.7585 8.85742C16.7658 8.97286 16.7658 9.09321 16.7658 9.21111C16.7658 12.8167 14.0199 16.9701 9.00193 16.9701C7.45456 16.9701 6.02016 16.5206 4.81173 15.7469C5.03279 15.7715 5.24402 15.7813 5.46998 15.7813C6.74718 15.7813 7.92123 15.349 8.85702 14.6171C7.65842 14.5925 6.65139 13.8066 6.30753 12.7259C6.72753 12.7873 7.10578 12.7873 7.53806 12.6767C6.9209 12.5514 6.36617 12.2162 5.96813 11.7281C5.57009 11.2401 5.35328 10.6293 5.35454 9.99953V9.96515C5.7156 10.169 6.14051 10.2943 6.58508 10.3115C6.21135 10.0624 5.90486 9.72496 5.69278 9.32908C5.4807 8.93319 5.36958 8.49109 5.36928 8.04198C5.36928 7.53355 5.50191 7.06934 5.74016 6.66653C6.42519 7.50982 7.28 8.19952 8.24903 8.69081C9.21807 9.1821 10.2796 9.46398 11.3648 9.51813C10.9791 7.66373 12.3644 6.16302 14.0297 6.16302C14.8157 6.16302 15.523 6.49214 16.0216 7.02267C16.6381 6.90723 17.2276 6.67636 17.7532 6.36688C17.5493 6.99811 17.122 7.5311 16.5546 7.86759C17.1048 7.80864 17.6353 7.65636 18.1265 7.44268C17.7557 7.98794 17.2915 8.47181 16.7585 8.85742Z"
                          fill="#4271A8"
                        />
                      </svg>
                    </div>
                    <div className="footer-mobile-bottom-icon">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0.541016 11.0429C0.541016 16.2242 4.30412 20.5327 9.22577 21.4064V13.8793H6.62035V10.9847H9.22577V8.66847C9.22577 6.06305 10.9045 4.61617 13.279 4.61617C14.0311 4.61617 14.8422 4.73167 15.5943 4.84718V7.5108H14.2629C12.9889 7.5108 12.6997 8.14739 12.6997 8.95854V10.9847H15.4788L15.0159 13.8793H12.6997V21.4064C17.6213 20.5327 21.3844 16.2251 21.3844 11.0429C21.3844 5.27881 16.6947 0.562988 10.9627 0.562988C5.23079 0.562988 0.541016 5.27881 0.541016 11.0429Z"
                          fill="#4271A8"
                        />
                      </svg>
                    </div>
                    <div className="footer-mobile-bottom-icon">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.0429 0.226562C5.19383 0.226562 0.453125 4.96815 0.453125 10.8164C0.453125 16.6646 5.19383 21.4062 11.0429 21.4062C16.8911 21.4062 21.6327 16.6646 21.6327 10.8164C21.6327 4.96815 16.8911 0.226562 11.0429 0.226562ZM11.1665 17.0096C7.75303 17.0096 4.98909 14.2386 4.98909 10.8164C4.98909 7.39409 7.75303 4.6231 11.1665 4.6231C12.8344 4.6231 14.2287 5.23819 15.2983 6.23716L13.5562 7.98271V7.97918C12.9076 7.35968 12.0851 7.04198 11.1665 7.04198C9.12794 7.04198 7.47152 8.76812 7.47152 10.8128C7.47152 12.8558 9.12794 14.5872 11.1665 14.5872C13.0162 14.5872 14.2746 13.5265 14.534 12.0704H11.1665V9.65502H16.9776C17.0553 10.0698 17.0968 10.5022 17.0968 10.9558C17.0968 14.4946 14.7344 17.0096 11.1665 17.0096Z"
                          fill="#4271A8"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Footer;
