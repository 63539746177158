import React from 'react';
import { useEffect, useState} from 'react';
import Menu from '../Menu/Menu';
import './poz.sass'
import Footer from './../Footer/Footer';
import {saveAs} from 'file-saver'
const Poz = () => {
    const [mobile, setMobile] = useState(window.innerWidth > 1200 ? false : true)
    useEffect(() => {
        window.addEventListener('resize', handleResize)
    }, [])
    // useEffect(() => {
    //     if (loc.hash === ''){
    //         let a = document.getElementById('a');
    //         a.scrollIntoView({behavior: 'smooth'})
    //         return
    //     }
    //     let hash = loc.hash.replace('#', '')
    //     let a = document.getElementById(hash)
    //     a.scrollIntoView({behavior: 'smooth'})
    // },[loc])
    const handleResize = () => {

          setMobile(window.innerWidth > 1200 ? false : true)
      }
    const fetchPDF = (a) => {
        fetch(a).then(response => {
            response.blob({type: 'application/pdf'}).then(blob => {
                // const fileURL = window.URL.createObjectURL(blob)
                // let alink = document.createElement('a')
                // alink.href = fileURL
                // alink.download = a
                // alink.click()
                saveAs(blob, a)
            })
        })
    }
    return (
        <>
            <Menu/>
            {mobile ? (<>
            <div className="mobile-header-wrapper">
              <div className="mobile-header-wrapper-content">
                <div className="mobile-header-wrapper-content-background"></div>
                <h1>Lekarz rodzinny</h1>
                <p>Podstawowa Opieka Zdrowotna NFZ</p>
              </div>
            </div> 
                <div className="mobile-poz-content">
                    <h2 id='a'>Informacje o POZ</h2>
                    
                    <h3>Lekarz:</h3>
                    <p>Dr Natalia Ferberg-Ulanowska</p>
                    <p>Dr Łukasz Pilarski</p>
                    {/* <h4>Godziny przyjęć:</h4>
                    <ul>
                        <li><p>Poniedziałek - wtorek: 13-18</p></li>
                        <li><p>środa - piątek: 8-13</p></li>
                    </ul> */}
                    {/* <p>dr Michał Antczak</p>
                    <h4>Godziny przyjęć:</h4>
                    <ul>
                        <li><p>Poniedziałek - wtorek: 8-13</p></li>
                        <li><p>środa - piątek: 13-18</p></li>
                    </ul>*/}
                    <h3>Pielęgniarka środowiskowa:</h3> 
                    
                    <h3>Godziny przyjęć:</h3>
                    <ul>
                      <li>Poniedziałek - Piątek: 8-12</li>
                    </ul>
                    
                    <h3>Aby zapisać się do naszej przychodni POZ należy:</h3>
                    <ol>
                        <li><p>Kliknąć w link poniżej w celu pobrania deklaracji</p></li>
                        <li><p>Wydrukować pobraną deklarację, wypisać potrzebne informacje oraz podpisać dokument RODO</p></li>
                        <li><p>Deklaracje złożyć w recepcji CRO-MED lub zeskanować wypisane dokumenty i wysłać na adres email: <strong>recepcja@cro-med.pl</strong></p></li>
                        {/* <p>Złożenie deklaracji jest <strong>bezpłatne</strong></p> */}
                        <p>Przed złożeniem deklaracji Pacjent <strong>nie musi</strong> wypisywać się z poprzedniej placówki, w której korzystał z Podstawowej Opieki Zdrowotnej.</p>
                        <a href="#" onClick={() => fetchPDF("Objasnienia.pdf")}><p> Objaśnienie dla składających deklaracje POZ</p></a>
                        
                    </ol>

                    <h3 id="files">Pliki do pobrania:</h3>
                    <ol>
                        <li><a href="#" onClick={() => fetchPDF("Deklaracja1.pdf")}>Deklaracja wyboru świadczeniodawcy udzielającego świadczeń z zakresu podstawowej opieki zdrowotnej oraz lekarza POZ</a>
                        </li><li><a href="#" onClick={() => fetchPDF("deklaracjePS.pdf")}>Deklaracja wyboru świadczeniodawcy udzielającego świadczeń z zakresu podstawowej opieki zdrowotnej oraz pielęgniarki POZ</a>
                    </li><li><a href="#" onClick={() => fetchPDF("Cromed_oswiadczenie_pacjenta.pdf")}>Oświadczenie pacjenta o upoważnieniu osoby bliskiej do uzyskiwania informacji o stanie zdrowia i udzielonych świadczeniach zdrowotnych</a>
                    </li><li><a href="#" onClick={() => fetchPDF("RODO_nowe_oświadczenie pacjenta_01.2022.docx")}>Oświadczenie RODO</a>
                    </li></ol>

                    <h4>Kontakt do recepcji</h4>
                    <p><strong>Nr. tel:</strong> +48 61 648 64 85 <br/>
                    <strong>Email:</strong> recepcja@cro-med.pl</p>
                    
                </div>
            </>):(<>
            <div className="header-wrapper" style={{height: '500px'}}>
            <svg
                style={{transform: 'scale(1.1)'}}
                className="header-wrapper-svg"
                width="1920"
                height="527"
                viewBox="0 0 1920 527"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1323.5 520.677C699.9 543.947 83.3333 497.357 0 472.074V0.782305H1922V472.074C1917.5 473.179 1947.1 497.406 1323.5 520.677Z"
                  fill="url(#paint0_linear)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="-4.31637e-06"
                    y1="230.818"
                    x2="1921.88"
                    y2="207.635"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop
                      offset="0.0574296"
                      stopColor="#243959"
                      stopOpacity="0.92"
                    />
                    <stop
                      offset="0.878291"
                      stopColor="#1A9AE2"
                      stopOpacity="0.58"
                    />
                  </linearGradient>
                </defs>
              </svg>
              <h1 className="header-wrapper-content-title" id="a" >Lekarz rodzinny</h1>
              <p className="header-wrapper-content-title-sub">
                Podstawowa Opieka Zdrowotna NFZ
              </p>
            </div>
            <div className="mobile-poz-content">
                    <h2 style={{fontSize: '2.5rem', marginTop: '100px'}}>Informacje o POZ</h2>
                    
                    <h3>Lekarz:</h3>
                    <p>dr Natalia Ferberg-Ulanowska</p>
                    <p>Dr Łukasz Pilarski</p>
                    {/* <h4>Godziny przyjęć:</h4>
                    <ul>
                        <li><p>Poniedziałek - wtorek: 13-18</p></li>
                        <li><p>środa - piątek: 8-13</p></li>
                    </ul>
                    <p>dr Michał Antczak</p>
                    <h4>Godziny przyjęć:</h4>
                    <ul>
                        <li><p>Poniedziałek - wtorek: 8-13</p></li>
                        <li><p>środa - piątek: 13-18</p></li>
                    </ul> */}
                    <h3>Pielęgniarka środowiskowa:</h3>
                    <h3>Godziny przyjęć:</h3>
                    <ul>
                      <li>Poniedziałek - Piątek: 8-12</li>
                    </ul>
                    <h3>Aby zapisać się do naszej przychodni POZ należy:</h3>
                    <ol>
                        <li><p>Kliknąć w link poniżej w celu pobrania deklaracji</p></li>
                        <li><p>Wydrukować pobraną deklarację, wypisać potrzebne informacje oraz podpisać dokument RODO</p></li>
                        <li><p>Deklaracje złożyć w recepcji CRO-MED lub zeskanować wypisane dokumenty i wysłać na adres email: <strong>recepcja@cro-med.pl</strong></p></li>
                        {/* <p>Złożenie deklaracji jest <strong>bezpłatne</strong></p> */}
                        <p>Przed złożeniem deklaracji Pacjent <strong>nie musi</strong> wypisywać się z poprzedniej placówki, w której korzystał z Podstawowej Opieki Zdrowotnej.</p>
                        <a href="#" onClick={() => fetchPDF("Objasnienia.pdf")}><p> Objaśnienie dla składających deklaracje POZ</p></a>
                        
                    </ol>

                    <h3 id="files">Pliki do pobrania:</h3>
                    <ol>
                        <li><p className="link" onClick={() => fetchPDF("Deklaracja1.pdf")}>Deklaracja wyboru świadczeniodawcy udzielającego świadczeń z zakresu podstawowej opieki zdrowotnej oraz lekarza POZ</p>
                        </li><li><p className="link" onClick={() => fetchPDF("deklaracjePS.pdf")}>Deklaracja wyboru świadczeniodawcy udzielającego świadczeń z zakresu podstawowej opieki zdrowotnej oraz pielęgniarki POZ</p>
                    </li><li><p className="link" onClick={() => fetchPDF("Cromed_oswiadczenie_pacjenta.pdf")}>Oświadczenie pacjenta o upoważnieniu osoby bliskiej do uzyskiwania informacji o stanie zdrowia i udzielonych świadczeniach zdrowotnych</p>
                    </li><li><p className="link" onClick={() => fetchPDF("RODO_nowe_oświadczenie pacjenta_01.2022.docx")}>Oświadczenie RODO</p>
                    </li></ol>

                    <h4>Kontakt do recepcji</h4>
                    <p><strong>Nr. tel:</strong> +48 61 648 64 85 <br/>
                    <strong>Email:</strong> recepcja@cro-med.pl</p>
                
                </div>
            
            </>)}
            <Footer />
        </>
    )
};

export default Poz;