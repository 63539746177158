import React, { Component } from 'react';
import Menu from '../Menu/Menu';
import './About.sass';
import FAQ from './FAQ.jsx';
import Footer from '../Footer/Footer';
export default class About extends Component {
  constructor() {
    super();
    this.state = {
      question: -1,
      height: '',
    };

    this.handleResize = this.handleResize.bind(this);

    this.question = React.createRef(null);
  }

  componentDidMount() {
    this.setState({
      mobile: window.innerWidth > 1200 ? false : true,
    });
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  handleResize() {
    this.setState({
      mobile: window.innerWidth > 1200 ? false : true,
    });
  }
  render() {
    return (
      <>
        <Menu />
        {/* svg with title */}
        {this.state.mobile ? (
          <>
            <div className="mobile-header-wrapper">
              <div className="mobile-header-wrapper-content">
                <div className="mobile-header-wrapper-content-background"></div>
                <h1>FAQ</h1>
                <p>
                  Kilka słów na temat{' '}
                  <span style={{ fontWeight: '300' }}>CROMED</span>
                </p>
              </div>
            </div>
            <div className="mobile-content-wrapper">
              <div className="mobile-content-wrapper-content">
                <FAQ />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="header-wrapper">
              {/* <img src={AboutPng} className="header-wrapper-img" alt="About picture" /> */}
              <svg
                className="header-wrapper-svg"
                width="1920"
                height="527"
                viewBox="0 0 1920 527"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1323.5 520.677C699.9 543.947 83.3333 497.357 0 472.074V0.782305H1922V472.074C1917.5 473.179 1947.1 497.406 1323.5 520.677Z"
                  fill="url(#paint0_linear)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="-4.31637e-06"
                    y1="230.818"
                    x2="1921.88"
                    y2="207.635"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop
                      offset="0.0574296"
                      stopColor="#243959"
                      stopOpacity="0.92"
                    />
                    <stop
                      offset="0.878291"
                      stopColor="#1A9AE2"
                      stopOpacity="0.58"
                    />
                  </linearGradient>
                </defs>
              </svg>
              <h1 className="header-wrapper-content-title">FAQ</h1>
              <p className="header-wrapper-content-title-sub">
                Kilka słów na temat CROMED
              </p>
            </div>
            <div className="content-wrapper">
              <div className="content-wrapper-content">
                <FAQ />
              </div>
            </div>
          </>
        )}
        <Footer />
      </>
    );
  }
}
