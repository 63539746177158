import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import ShowOfferts from './Offerts/showOfferts';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useForm } from 'react-hook-form';
import { getDatabase, ref, set } from 'firebase/database';

function HomeCMS() {
  const [user, setUser] = useState();

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (_user) => {
      console.log(_user);
      setUser(_user);
    });
  }, []);
  const {
    register,
    // eslint-disable-next-line no-unused-vars
    setError,
    // eslint-disable-next-line no-unused-vars
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    console.log(data.slider1Title);
    const db = getDatabase();
    set(ref(db, 'sliders/slider1'), {
      title: data.slider1Title,
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid sx={3}>
          <h1>hello, {user?.email ? user.email : 'loading...'}</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input {...register('slider1Title')} />
            <button type="submit">Zaloguj</button>
          </form>
        </Grid>
        <Grid sx={9}>
          <Routes>
            <Route exact path={'helo'} component={<h1>hello</h1>} />
            <Route exact path={'/admin/offerts'} element={<ShowOfferts />} />
          </Routes>
        </Grid>
      </Grid>
    </>
  );
}
export default HomeCMS;
