import React, { useState, useEffect, useLayoutEffect } from 'react';
import './Menu.sass';

import { MenuCategories, MenuLogo } from './Desktop';
// import {slide as MenuMobile} from 'react-burger-menu'
import MobileMenuLogo from './Mobile/Logo/MobileMenuLogo';
import MobileMenuHamburger from './Mobile/Hamburger/MobileMenuHamburger';
import MobileMenuContent from './Mobile/Content/MobileMenuContent';

const Menu = () => {
  const [menuOpened, setMenuOpened] = useState(false);
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    setMobile(window.innerWidth > 1200 ? false : true);
    window.addEventListener('resize', handleResize);
  }, []);

  useLayoutEffect(() => {
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    setMobile(window.innerWidth > 1200 ? false : true);
  };

  return (
    <>
      {!mobile ? (
        <div className="menu-wrapper">
          <MenuLogo />
          <div className="menu-categories-wrapper">
            <MenuCategories />
          </div>
        </div>
      ) : (
        <div className="menu-mobile-wrapper">
          <div className="menu-mobile-content">
            <MobileMenuLogo />
            <MobileMenuHamburger
              menuOpened={menuOpened}
              setMenuOpened={setMenuOpened}
            />
            <MobileMenuContent menuOpened={menuOpened} />
          </div>
        </div>
        // <MenuMobile/>
      )}
    </>
  );
};

export default Menu;
