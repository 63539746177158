import React from 'react';
import './Login.sass';
import logo from '../../../assets/cro720_1.webp';
// import {useForm, useField, splitFormProps} from 'react-form'
//import { initializeApp } from 'firebase/app'
import {
  getAuth,
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getDatabase, ref, set } from 'firebase/database';
// function validateLogin(name) {
//     if(!name || name.length === 0 || name.trim() === '') {
//         return "Login nie jest poprawny"
//     }
//     return false
// }

function Login() {
  //
  //     const [error, setError] = useState(false)
  //
  //     let location = useHistory()
  //         const auth = getAuth()
  //         // onAuthStateChanged(auth, (user) => {
  //         //     if(user){
  //         //         location.push('/admin')
  //         //     }else{
  //
  //         //     }
  //         // })
  //     const {Form, meta: {isSubmitting, canSubmit}} = useForm({
  //         onSubmit: async (values, instance) => {
  //             console.log(values);
  //             const auth = getAuth();
  //             signInWithEmailAndPassword(auth, values.cromed_email, values.cromed_password).then((user) => {
  //                 console.log(user)
  //                 setPersistence(auth, browserLocalPersistence).then(() => {
  //                     return signInWithEmailAndPassword(auth, values.cromed_email, values.cromed_password)
  //                 })
  //             }).catch(e => {
  //                 if(e.message.toLowerCase().includes('(auth/too-many-requests)')){
  //                     setError("Wykonano zbyt dużo zapytań na raz. Konto zostało tymczasowo zablokowane")
  //                 }else if(e.message.toLowerCase().includes('missing_email')){
  //                     setError('Został podany nieprawidłowy email')
  //                 }else if(e.message.includes('INVALID_PASSWORD')){
  //                     setError('Został podany nieprawidłowy email lub/i hasło')
  //                 }
  //             })
  //         }
  //     })
  //
  //     return (
  //         <div className="login-container">
  //             <div className="row">
  //                 <div className=""><img
  //                   onClick={() => (window.location.href = "/")}
  //                   src={logo}
  //                   alt="logo"
  //                 ></img>
  //                 <h3>Logowanie do panelu</h3></div>
  //                 <Form>
  //                 <div>
  //                     <label>Login</label>
  //                     <InputField field="cromed_email" name="cromed_email" validate={validateLogin} />
  //                 </div>
  //                 <div>
  //                     <label>Hasło</label>
  //                     <InputField type="password" field="cromed_password" name="cromed_password" validate={validateLogin} />
  //                 </div>
  //                 <div>
  //                     <button type="submit" >
  //                     Zaloguj
  //                     </button>
  //                 </div>
  //                 {isSubmitting ? "Logowanie.." : null}
  //             {error ? <span>{error}</span> : null}
  //             </Form>
  //
  //             </div>
  //
  //         </div>
  //
  //     )

  const navigate = useNavigate();
  const {
    register,
    setError,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    const auth = getAuth();
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        signInWithEmailAndPassword(auth, data.login, data.pass)
          .then((user) => {
            console.log(user);
            const db = getDatabase();
            set(ref(db, 'users/' + user.user.uid), {
              lastLogin: new Date().getTime(),
            });
            return navigate('/admin');
          })
          .catch((e) => {
            console.error(e);
            console.info(`INFORMACJA NETTOWA: \n ${e.code}`);
            if (e.message.toLowerCase().includes('(auth/too-many-requests)')) {
              setError('login', {
                message:
                  'Wykonano zbyt dużo zapytań na raz. Konto zostało tymczasowo zablokowane',
                type: 'too_many',
              });
            } else if (e.message.toLowerCase().includes('missing_email')) {
              setError('Został podany nieprawidłowy email');
            } else if (
              e.message.includes('INVALID_PASSWORD') ||
              e.code.includes('auth/wrong-password')
            ) {
              setError('pass', {
                message: 'Został podany nieprawidłowy email lub/i hasło',
                type: 'wrong_password',
              });
            } else if (e.code.includes('auth/user-not-found')) {
              setError('login', { type: 'bad_logon' });
            }
          });
      })
      .catch(() => console.log);
  };
  return (
    <div className="login-container">
      <div className="row">
        <div className="">
          <img
            onClick={() => (window.location.href = '/')}
            src={logo}
            alt="logo"
          />
          <h3>Logowanie do panelu</h3>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label>Login</label>
            <input {...register('login', { required: true })} />
            {errors.login?.type === 'required' && 'Login jest wymagany!'}
            {errors.login?.type === 'bad_logon' && 'Login jest nieprawidłowy!'}
          </div>
          <div>
            <label>Hasło</label>
            <input type="password" {...register('pass', { required: true })} />
            {errors.pass?.type === 'required' && 'Hasło jest wymagane!'}
            {errors.pass?.type === 'wrong_password' &&
              'Hasło jest nieprawidłowe!'}
          </div>
          <div>
            {errors.login?.type === 'too_many' &&
              'Wykonano zbyt dużo logowań. Odczekaj chwilę'}
            <button type="submit">Zaloguj</button>
          </div>
          {/*    {isSubmitting ? "Logowanie.." : null}*/}
          {/*{error ? <span>{error}</span> : null}*/}
        </form>
      </div>
    </div>
  );
}

export default Login;
