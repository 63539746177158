import React from 'react';
import './Contact.sass';
import Menu from '../Menu/Menu';
import Footer from '../Footer/Footer';
export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      content: '',
      emailErr: '',
    };

    this.handleResize = this.handleResize.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this.setState({
      mobile: window.innerWidth > 1200 ? false : true,
    });
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  handleResize() {
    this.setState({
      mobile: window.innerWidth > 1200 ? false : true,
    });
  }
  handleSubmit() {
    // e.preventDefault()
    if (this.state.email.indexOf('@') === -1) {
      return this.setState({
        emailErr: 'Email jest nie prawidłowy',
        email: '',
      });
    }
    fetch('https://api.desite.pl/v1/cromed/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({
        email: this.state.email,
        content: this.state.content,
        name: 'a',
      }),
    })
      .then((data) => {
        return data;
      })
      .then((data) => {
        this.setState({ recived: data });
      })
      .catch((e) => {
        console.log(e);
      });
  }
  render() {
    return (
      <>
        <Menu />
        {this.state.mobile ? (
          <>
            <div className="mobile-header-wrapper">
              <div className="mobile-header-wrapper-content">
                <div className="mobile-header-wrapper-content-background"></div>
                <h1>Kontakt</h1>
                <p>Skontaktuj się z nami!</p>
              </div>
            </div>
            <div className="content-wrapper">
              <div className="content-wrapper-content">
                <div className="content-info-box">
                  <h4 className="content-info-h4">Dane kontaktowe</h4>
                  <div className="content-info-pos">
                    <svg
                      width="19"
                      height="22"
                      viewBox="0 0 19 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.6122 15.4525L14.2222 13.6325C14.4391 13.3904 14.7134 13.2247 15.012 13.1555C15.3106 13.0863 15.6205 13.1165 15.9041 13.2425L17.8663 14.1282C18.1529 14.2597 18.3987 14.4842 18.5727 14.7734C18.7466 15.0625 18.841 15.4035 18.8438 15.7532V19.8157C18.8421 20.0535 18.7979 20.2886 18.7138 20.5066C18.6296 20.7247 18.5073 20.9212 18.3542 21.0844C18.2011 21.2476 18.0204 21.3741 17.8229 21.4563C17.6254 21.5385 17.4152 21.5746 17.205 21.5625C3.45536 20.5957 0.680988 7.43316 0.1563 2.39566C0.131944 2.14828 0.154198 1.89788 0.221598 1.66092C0.288997 1.42397 0.400016 1.20583 0.547349 1.02086C0.694682 0.835885 0.874991 0.688275 1.07641 0.587735C1.27784 0.487195 1.49581 0.436006 1.71599 0.437533H5.18755C5.49734 0.43857 5.79977 0.54435 6.05594 0.74127C6.31211 0.938189 6.51031 1.21724 6.62505 1.54253L7.40849 3.76066C7.52367 4.08003 7.55306 4.43068 7.49298 4.76882C7.4329 5.10696 7.28601 5.41759 7.07068 5.66191L5.46068 7.48191C5.46068 7.48191 6.38786 14.575 12.6122 15.4525Z"
                        fill="#5B5D6F"
                      />
                    </svg>
                    <p>
                      <a
                        href={'tel:533-035-616'}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        +48 61 648 64 85
                      </a>
                    </p>
                  </div>
                  <div className="content-info-pos">
                    <svg
                      width="22"
                      height="21"
                      viewBox="0 0 22 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.75 0.53125H1.25C0.835156 0.53125 0.5 0.93623 0.5 1.4375V19.5625C0.5 20.0638 0.835156 20.4688 1.25 20.4688H20.75C21.1648 20.4688 21.5 20.0638 21.5 19.5625V1.4375C21.5 0.93623 21.1648 0.53125 20.75 0.53125ZM18.8563 3.61533L11.4617 10.568C11.2789 10.7407 11.0234 10.7407 10.8406 10.568L3.44375 3.61533C3.41587 3.58933 3.39541 3.55344 3.38526 3.51268C3.37511 3.47193 3.37576 3.42836 3.38713 3.38808C3.3985 3.3478 3.42002 3.31283 3.44867 3.28807C3.47731 3.2633 3.51165 3.24999 3.54688 3.25H18.7531C18.7883 3.24999 18.8227 3.2633 18.8513 3.28807C18.88 3.31283 18.9015 3.3478 18.9129 3.38808C18.9242 3.42836 18.9249 3.47193 18.9147 3.51268C18.9046 3.55344 18.8841 3.58933 18.8563 3.61533V3.61533Z"
                        fill="#5B5D6F"
                      />
                    </svg>
                    <p>
                      <a
                        href={'mailto:recepcja@cro-med.pl'}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        recepcja@cro-med.pl
                      </a>
                    </p>
                  </div>
                  <div className="content-info-pos">
                    <svg
                      width="18"
                      height="28"
                      viewBox="0 0 18 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.00001 0.875C6.63049 0.878225 4.35883 1.96575 2.68333 3.89902C1.00783 5.83229 0.0653049 8.45345 0.0625095 11.1875C0.0596715 13.4218 0.692181 15.5954 1.86301 17.375C1.86301 17.375 2.10676 17.7453 2.14657 17.7988L9.00001 27.125L15.8567 17.7941C15.8924 17.7444 16.137 17.375 16.137 17.375L16.1378 17.3722C17.3081 15.5934 17.9403 13.4207 17.9375 11.1875C17.9347 8.45345 16.9922 5.83229 15.3167 3.89902C13.6412 1.96575 11.3695 0.878225 9.00001 0.875V0.875ZM9.00001 14.9375C8.35722 14.9375 7.72887 14.7176 7.19441 14.3055C6.65995 13.8935 6.24339 13.3078 5.9974 12.6226C5.75142 11.9373 5.68705 11.1833 5.81246 10.4559C5.93786 9.72848 6.24739 9.0603 6.70191 8.53585C7.15643 8.0114 7.73553 7.65425 8.36597 7.50955C8.9964 7.36486 9.64987 7.43912 10.2437 7.72295C10.8376 8.00678 11.3452 8.48743 11.7023 9.10411C12.0594 9.7208 12.25 10.4458 12.25 11.1875C12.2489 12.1817 11.9062 13.1348 11.2969 13.8378C10.6877 14.5408 9.86163 14.9363 9.00001 14.9375V14.9375Z"
                        fill="#5B5D6F"
                      />
                    </svg>

                    <p>
                      <a
                        href={'https://goo.gl/maps/RntG1XRdy3StA897A'}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Os. Raczynskiego 2/23, Swarzędz
                      </a>
                    </p>
                  </div>
                  <h4 className="content-info-h4">Social media</h4>
                  <div className="content-info-pos">
                    <svg
                      width="22"
                      height="21"
                      viewBox="0 0 22 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 10.5586C0 15.7789 3.97192 20.1197 9.16667 21V13.4164H6.41667V10.5H9.16667V8.16638C9.16667 5.54138 10.9386 4.08362 13.4447 4.08362C14.2386 4.08362 15.0948 4.2 15.8886 4.31638V7H14.4833C13.1386 7 12.8333 7.64137 12.8333 8.45862V10.5H15.7667L15.2781 13.4164H12.8333V21C18.0281 20.1197 22 15.7798 22 10.5586C22 4.75125 17.05 0 11 0C4.95 0 0 4.75125 0 10.5586Z"
                        fill="#5B5D6F"
                      />
                    </svg>

                    <p>
                      <a
                        href={'https://www.facebook.com/CromedFizjoterapia'}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Facebook.com/Cromed
                      </a>
                    </p>
                  </div>
                  <div className="content-info-pos">
                    <svg
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.75 0.125H3.25C1.47917 0.125 0.125 1.47917 0.125 3.25V15.75C0.125 17.5208 1.47917 18.875 3.25 18.875H15.75C17.5208 18.875 18.875 17.5208 18.875 15.75V3.25C18.875 1.47917 17.5208 0.125 15.75 0.125ZM9.5 6.375C11.2708 6.375 12.625 7.72917 12.625 9.5C12.625 11.2708 11.2708 12.625 9.5 12.625C7.72917 12.625 6.375 11.2708 6.375 9.5C6.375 7.72917 7.72917 6.375 9.5 6.375ZM13.4583 4.29167C13.4583 3.5625 14.0833 3.04167 14.7083 3.04167C15.3333 3.04167 15.9583 3.66667 15.9583 4.29167C15.9583 4.91667 15.4375 5.54167 14.7083 5.54167C13.9792 5.54167 13.4583 5.02083 13.4583 4.29167V4.29167ZM15.75 16.7917H3.25C2.625 16.7917 2.20833 16.375 2.20833 15.75V9.5H4.29167C4.29167 12.4167 6.58333 14.7083 9.5 14.7083C12.4167 14.7083 14.7083 12.4167 14.7083 9.5H16.7917V15.75C16.7917 16.375 16.375 16.7917 15.75 16.7917Z"
                        fill="#5B5D6F"
                      />
                    </svg>

                    <p>
                      <a
                        href={'mailto:kontakt@cro-med.pl'}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Instagram.com/Cromed
                      </a>
                    </p>
                  </div>
                </div>
                <div className="content-info-box">
                  <div className="info-input-box">
                    <p className="info-input-label">Email</p>
                    <input
                      className="info-input"
                      value={this.state.email}
                      name="email"
                      placeholder={
                        this.state.emailErr ? this.state.emailErr : null
                      }
                      type="email"
                      onChange={(e) => {
                        this.setState({
                          [e.target.name]: e.target.value,
                          emailErr: '',
                        });
                      }}
                    />
                  </div>
                  <div className="info-input-box">
                    <p className="info-input-label">Wiadomość</p>
                    <textarea
                      className="info-input-textarea"
                      name="content"
                      type="text"
                      onChange={(e) => {
                        this.setState({ [e.target.name]: e.target.value });
                      }}
                    />
                  </div>
                  <div className="info-input-btn" onClick={this.handleSubmit}>
                    <p>Wyślij!</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="header-wrapper">
              {/* <img src={AboutPng} className="header-wrapper-img" alt="About picture" /> */}
              <svg
                className="header-wrapper-svg"
                width="1920"
                height="527"
                viewBox="0 0 1920 527"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1323.5 520.677C699.9 543.947 83.3333 497.357 0 472.074V0.782305H1922V472.074C1917.5 473.179 1947.1 497.406 1323.5 520.677Z"
                  fill="url(#paint0_linear)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="-4.31637e-06"
                    y1="230.818"
                    x2="1921.88"
                    y2="207.635"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop
                      offset="0.0574296"
                      stopColor="#243959"
                      stopOpacity="0.92"
                    />
                    <stop
                      offset="0.878291"
                      stopColor="#1A9AE2"
                      stopOpacity="0.58"
                    />
                  </linearGradient>
                </defs>
              </svg>
              <h1 className="header-wrapper-content-title">Kontakt</h1>
              <p className="header-wrapper-content-title-sub">
                Skontaktuj się z nami!
              </p>
            </div>
            <div className="content-wrapper">
              <div className="content-wrapper-content">
                <div className="content-info-box">
                  <h4 className="content-info-h4">Dane kontaktowe</h4>
                  <div className="content-info-pos">
                    <svg
                      width="19"
                      height="22"
                      viewBox="0 0 19 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.6122 15.4525L14.2222 13.6325C14.4391 13.3904 14.7134 13.2247 15.012 13.1555C15.3106 13.0863 15.6205 13.1165 15.9041 13.2425L17.8663 14.1282C18.1529 14.2597 18.3987 14.4842 18.5727 14.7734C18.7466 15.0625 18.841 15.4035 18.8438 15.7532V19.8157C18.8421 20.0535 18.7979 20.2886 18.7138 20.5066C18.6296 20.7247 18.5073 20.9212 18.3542 21.0844C18.2011 21.2476 18.0204 21.3741 17.8229 21.4563C17.6254 21.5385 17.4152 21.5746 17.205 21.5625C3.45536 20.5957 0.680988 7.43316 0.1563 2.39566C0.131944 2.14828 0.154198 1.89788 0.221598 1.66092C0.288997 1.42397 0.400016 1.20583 0.547349 1.02086C0.694682 0.835885 0.874991 0.688275 1.07641 0.587735C1.27784 0.487195 1.49581 0.436006 1.71599 0.437533H5.18755C5.49734 0.43857 5.79977 0.54435 6.05594 0.74127C6.31211 0.938189 6.51031 1.21724 6.62505 1.54253L7.40849 3.76066C7.52367 4.08003 7.55306 4.43068 7.49298 4.76882C7.4329 5.10696 7.28601 5.41759 7.07068 5.66191L5.46068 7.48191C5.46068 7.48191 6.38786 14.575 12.6122 15.4525Z"
                        fill="#5B5D6F"
                      />
                    </svg>
                    <p>
                      <a
                        href={'tel:533-035-616'}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        +48 61 648 64 85
                      </a>
                    </p>
                  </div>
                  <div className="content-info-pos">
                    <svg
                      width="22"
                      height="21"
                      viewBox="0 0 22 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.75 0.53125H1.25C0.835156 0.53125 0.5 0.93623 0.5 1.4375V19.5625C0.5 20.0638 0.835156 20.4688 1.25 20.4688H20.75C21.1648 20.4688 21.5 20.0638 21.5 19.5625V1.4375C21.5 0.93623 21.1648 0.53125 20.75 0.53125ZM18.8563 3.61533L11.4617 10.568C11.2789 10.7407 11.0234 10.7407 10.8406 10.568L3.44375 3.61533C3.41587 3.58933 3.39541 3.55344 3.38526 3.51268C3.37511 3.47193 3.37576 3.42836 3.38713 3.38808C3.3985 3.3478 3.42002 3.31283 3.44867 3.28807C3.47731 3.2633 3.51165 3.24999 3.54688 3.25H18.7531C18.7883 3.24999 18.8227 3.2633 18.8513 3.28807C18.88 3.31283 18.9015 3.3478 18.9129 3.38808C18.9242 3.42836 18.9249 3.47193 18.9147 3.51268C18.9046 3.55344 18.8841 3.58933 18.8563 3.61533V3.61533Z"
                        fill="#5B5D6F"
                      />
                    </svg>
                    <p>
                      <a
                        href={'mailto:recepcja@cro-med.pl'}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        recepcja@cro-med.pl
                      </a>
                    </p>
                  </div>
                  <div className="content-info-pos">
                    <svg
                      width="18"
                      height="28"
                      viewBox="0 0 18 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.00001 0.875C6.63049 0.878225 4.35883 1.96575 2.68333 3.89902C1.00783 5.83229 0.0653049 8.45345 0.0625095 11.1875C0.0596715 13.4218 0.692181 15.5954 1.86301 17.375C1.86301 17.375 2.10676 17.7453 2.14657 17.7988L9.00001 27.125L15.8567 17.7941C15.8924 17.7444 16.137 17.375 16.137 17.375L16.1378 17.3722C17.3081 15.5934 17.9403 13.4207 17.9375 11.1875C17.9347 8.45345 16.9922 5.83229 15.3167 3.89902C13.6412 1.96575 11.3695 0.878225 9.00001 0.875V0.875ZM9.00001 14.9375C8.35722 14.9375 7.72887 14.7176 7.19441 14.3055C6.65995 13.8935 6.24339 13.3078 5.9974 12.6226C5.75142 11.9373 5.68705 11.1833 5.81246 10.4559C5.93786 9.72848 6.24739 9.0603 6.70191 8.53585C7.15643 8.0114 7.73553 7.65425 8.36597 7.50955C8.9964 7.36486 9.64987 7.43912 10.2437 7.72295C10.8376 8.00678 11.3452 8.48743 11.7023 9.10411C12.0594 9.7208 12.25 10.4458 12.25 11.1875C12.2489 12.1817 11.9062 13.1348 11.2969 13.8378C10.6877 14.5408 9.86163 14.9363 9.00001 14.9375V14.9375Z"
                        fill="#5B5D6F"
                      />
                    </svg>

                    <p>
                      <a
                        href={'https://goo.gl/maps/RntG1XRdy3StA897A'}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Os. Raczynskiego 2/23, Swarzędz
                      </a>
                    </p>
                  </div>
                  <h4 className="content-info-h4">Social media</h4>
                  <div className="content-info-pos">
                    <svg
                      width="22"
                      height="21"
                      viewBox="0 0 22 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 10.5586C0 15.7789 3.97192 20.1197 9.16667 21V13.4164H6.41667V10.5H9.16667V8.16638C9.16667 5.54138 10.9386 4.08362 13.4447 4.08362C14.2386 4.08362 15.0948 4.2 15.8886 4.31638V7H14.4833C13.1386 7 12.8333 7.64137 12.8333 8.45862V10.5H15.7667L15.2781 13.4164H12.8333V21C18.0281 20.1197 22 15.7798 22 10.5586C22 4.75125 17.05 0 11 0C4.95 0 0 4.75125 0 10.5586Z"
                        fill="#5B5D6F"
                      />
                    </svg>

                    <p>
                      <a
                        href={'https://www.facebook.com/CromedFizjoterapia'}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        facebook.com/cromed
                      </a>
                    </p>
                  </div>
                  <div className="content-info-pos">
                    <svg
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.75 0.125H3.25C1.47917 0.125 0.125 1.47917 0.125 3.25V15.75C0.125 17.5208 1.47917 18.875 3.25 18.875H15.75C17.5208 18.875 18.875 17.5208 18.875 15.75V3.25C18.875 1.47917 17.5208 0.125 15.75 0.125ZM9.5 6.375C11.2708 6.375 12.625 7.72917 12.625 9.5C12.625 11.2708 11.2708 12.625 9.5 12.625C7.72917 12.625 6.375 11.2708 6.375 9.5C6.375 7.72917 7.72917 6.375 9.5 6.375ZM13.4583 4.29167C13.4583 3.5625 14.0833 3.04167 14.7083 3.04167C15.3333 3.04167 15.9583 3.66667 15.9583 4.29167C15.9583 4.91667 15.4375 5.54167 14.7083 5.54167C13.9792 5.54167 13.4583 5.02083 13.4583 4.29167V4.29167ZM15.75 16.7917H3.25C2.625 16.7917 2.20833 16.375 2.20833 15.75V9.5H4.29167C4.29167 12.4167 6.58333 14.7083 9.5 14.7083C12.4167 14.7083 14.7083 12.4167 14.7083 9.5H16.7917V15.75C16.7917 16.375 16.375 16.7917 15.75 16.7917Z"
                        fill="#5B5D6F"
                      />
                    </svg>

                    <p>
                      <a
                        href={'https://www.instagram.com/cromedfizjoterapia/'}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        instagram.com/cromed
                      </a>
                    </p>
                  </div>
                </div>
                <div className="content-info-box">
                  <div className="info-input-box">
                    <p className="info-input-label">Email</p>
                    <input
                      className="info-input"
                      value={this.state.email}
                      name="email"
                      placeholder={
                        this.state.emailErr ? this.state.emailErr : null
                      }
                      type="email"
                      onChange={(e) => {
                        this.setState({
                          [e.target.name]: e.target.value,
                          emailErr: '',
                        });
                      }}
                    />
                  </div>
                  <div className="info-input-box">
                    <p className="info-input-label">Wiadomość</p>
                    <textarea
                      className="info-input-textarea"
                      name="content"
                      type="text"
                      onChange={(e) => {
                        this.setState({ [e.target.name]: e.target.value });
                      }}
                    />
                  </div>
                  <div className="info-input-btn" onClick={this.handleSubmit}>
                    <p>Wyślij!</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <Footer />
      </>
    );
  }
}
