import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { initializeApp } from 'firebase/app';
// import { CookiesProvider } from 'react-cookie';
// import {
//   browserSessionPersistence,
//   getAuth,
//   setPersistence,
// } from 'firebase/auth'

initializeApp({
  apiKey: 'AIzaSyBlJdKM-ozEYsIXOBde1uUB1JtBMhFBIro',
  authDomain: 'cromed-6a8eb.firebaseapp.com',
  projectId: 'cromed-6a8eb',
  storageBucket: 'cromed-6a8eb.appspot.com',
  messagingSenderId: '564498102537',
  appId: '1:564498102537:web:26dcfbddb984f50877fbd8',
  measurementId: 'G-GMVEGKVDZ2',
  databaseURL:
    'https://cromed-6a8eb-default-rtdb.europe-west1.firebasedatabase.app',
});
// let auth;
// auth = getAuth(app)
// (async () => {
//   await setPersistence(auth, browserSessionPersistence).then(console.log).catch(console.log)
// })();
// const analytics = getAnalytics(app);
ReactDOM.render(
  <React.StrictMode>
    {/* <CookiesProvider> */}
      <App />
    {/* </CookiesProvider> */}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
