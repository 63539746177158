import React from 'react';
import Menu from '../Menu/Menu';
import './employees.sass';
import {employees} from './employees.js'
import Footer from '../Footer/Footer';
import marcin from './employment/marcin.jpg'
import karolina from './employment/karolina.jpg'
import marlena from './employment/marlena.jpg'
class Employees extends React.Component {
  constructor() {
    super()
    this.state = {

    }
    this.photos = [
        karolina, marlena, marcin
    ]
  }

  render() {
    return(
        <>
            <Menu />   
            <div className="employees">
                <div className="employees-header">
                    <h1>Poznaj zespół Cromed</h1>
                </div>
                <div className="employees-container">
                    {employees.map((data, i) => {
                        return(
                            <>
                            <div className="employee">
                                <div className="employee-img">
                                    <img src={this.photos[i]} />
                                </div>
                                <div className="employee-name">
                                    <p>{data.name}</p>
                                </div>
                                <div className="employee-desc">
                                <p>{data.desc}</p>
                                </div>
                            </div>
                            </>
                        )
                    })}
                </div>
            </div>
            <Footer/>
        </>
    )
  }
}

export default Employees;
