import React from 'react';
import logo from '../../../../assets/cromed_granatowe_1.webp';

const MobileMenuLogo = () => {
  return (
    <img
      src={logo}
      width="150"
      className="menu-mobile-logo"
      onClick={() => (window.location.href = '/')}
    />
  );
};

export default MobileMenuLogo;
