import React from 'react';
import Menu from '../Menu/Menu';
import './privacy.sass';
import {useEffect, useState} from 'react'
const Privacy = () => {
    const [mobile, setMobile] = useState(window.innerWidth > 1200 ? false : true)
    useEffect(() => {
        window.addEventListener('resize', handleResize)
    }, [])
    const handleResize = () => {

        setMobile(window.innerWidth > 1200 ? false : true)
    }
    return (
        <>
            <Menu/>
            {mobile ? (<div className="mobile-header-wrapper">
              <div className="mobile-header-wrapper-content">
                <div className="mobile-header-wrapper-content-background"></div>
                <h1>Polityka prywatności</h1>
              </div>
            </div> ) : (<div className="header-wrapper" style={{height: '500px'}}>
            <svg
                className="header-wrapper-svg"
                width="1920"
                height="527"
                viewBox="0 0 1920 527"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1323.5 520.677C699.9 543.947 83.3333 497.357 0 472.074V0.782305H1922V472.074C1917.5 473.179 1947.1 497.406 1323.5 520.677Z"
                  fill="url(#paint0_linear)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="-4.31637e-06"
                    y1="230.818"
                    x2="1921.88"
                    y2="207.635"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop
                      offset="0.0574296"
                      stopColor="#243959"
                      stopOpacity="0.92"
                    />
                    <stop
                      offset="0.878291"
                      stopColor="#1A9AE2"
                      stopOpacity="0.58"
                    />
                  </linearGradient>
                </defs>
              </svg>
              <h1 className="header-wrapper-content-title" id="privacy-header">Polityka prywatności</h1>
              {/* <p className="header-wrapper-content-title-sub">
                Skontaktuj się z nami!
              </p> */}
            </div>)}
            
            <div className="privacy">
                <div className="privacy-content"><h2>POLITYKA PRYWATNOŚCI</h2>
                <p>Szanowni Państwo, <br/> Uprzejmie informujemy, iż administratorem Państwa danych osobowych
jest CRO-MED Gabinety Lekarskie i Fizjoterapii Sp. z o.o. z siedzibą w Swarzędzu os. E. Raczyńskiego
2/23, 62-020 Swarzędz (wpisaną do rejestru przedsiębiorców Krajowego Rejestru Sądowego
prowadzonego przez Sąd Rejonowy Poznań – Nowe Miasto i Wilda w Poznaniu, VIII Wydział
Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000991840, REGON 523103420, NIP
7773394832) w ramach realizacji obowiązków podmiotu leczniczego, w tym także w zakresie
profilaktyki zdrowotnej oraz zarządzania świadczeniami opieki zdrowotnej (Ustawa o prawach
pacjenta i Rzeczniku Praw Pacjenta i jej akty wykonawcze), w tym:</p>
    <ul>
        <li>Świadczenie opieki zdrowotnej,</li>
        <li>prowadzenie i przechowywania dokumentacji medycznej;</li>
        <li>komunikacji z pacjentem, np. potwierdzenie rezerwacji bądź odwołanie terminu konsultacji
lekarskiej, przypomnienie o konsultacji, poinformowanie o konieczności przygotowania się do
umówionego zabiegu bądź o możliwości odbioru wyniku badań;</li>
    </ul>
    <p>Ponad to, Administrator będzie przetwarzał dane osobowe w związku z realizacją przedmiotu Umowy
na świadczenie usług medycznych. Na podstawie polskich przepisów prawa Administrator jest
zobowiązany do przetwarzania danych osobowych w celu dokonania rozliczeń z tytułu prowadzonej
działalności gospodarczej. W interesie prawnym Administratora jest prowadzenie procesu
reklamacyjnego usług, obsługa wniosków pacjentów czy obrona przez roszczeniami.</p>
<p>Dane osobowe będą przetwarzane, co do zasady:</p>
<ul>
    <li>przez 20 lat – w związku z prowadzeniem i archiwizacją dokumentacji medycznej od
dokonania w niej ostatniego wpisu;</li>
    <li>przez 6 lat – w dochodzeniem i obroną przed roszczeniami w związku ze zrealizowanymi
usługami;</li>
    <li>przez 5 lat - na potrzeby rachunkowości oraz ze względów podatkowych, okres liczony jest od
końca roku kalendarzowego, w którym powstał obowiązek podatkowy;</li>
    <li>do czasu wniesienia sprzeciwu lub cofnięcia zgody na przetwarzanie danych osobowych, jeśli
dane są przetwarzane na takiej podstawie.</li>
</ul>
<p>Dane osobowe będą udostępnione pracowniom i osobom współpracującym na podstawie
stosowanego upoważnienia oraz podmiotom zewnętrznym, które wspierają Administratora w
realizacji celu na podstawie umowy powierzenia danych osobowych bądź przepisów prawa.</p>
<p>Dane osobowe będą przetwarzane w na podstawie przepisów Rozporządzenia Parlamentu
Europejskiego i Rady (UE) 2016/679 z 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w
związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz
uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) („RODO”).</p>
<p>W przypadku gdy uznają Państwo, że ich prawa zostały naruszone, przysługuje Państwu skarga do
Prezesa Urzędu Ochrony Danych Osobowych.</p>
<p>Kontakt mailowy: kontakt@cro-med.pl <br/> telefoniczny +48 533035616</p>
</div>
                
            </div>
        </>
    )
}

export default Privacy