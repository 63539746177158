import React from 'react';
import { Link } from 'react-router-dom';

const MenuCategories = () => {
  return (
    <div className="menu-categories">
      <div className="menu-category">
       <Link to='/poz'><p>
          Lekarz rodzinny{' '}
          <svg
            viewBox="0 1 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.313 1L9.5 11 1.687 1"
              stroke="#24679A"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </p></Link> 

        <div className="menu-link-dropdown">
          <div className="menu-link-dropdown-content">
            <div className="menu-link-dropdown-content-item">
              <p>
                <Link to="/poz">Lekarz rodzinny</Link>
              </p>
            </div>
            <div className="menu-link-dropdown-content-item">
              <p>
                <Link push to="/diagnostyka">Diagnostyka</Link>
              </p>
            </div>
            <div className="menu-link-dropdown-content-item">
              <p>
                <a href="https://wyniki.diag.pl">Wyniki online</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="menu-category">
        <p>
          Fizjoterapia{' '}
          <svg
            viewBox="0 1 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.313 1L9.5 11 1.687 1"
              stroke="#24679A"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </p>
        <div className="menu-link-dropdown">
          <div className="menu-link-dropdown-content">
            
            <div className="menu-link-dropdown-content-item">
              <p>
                <Link to="/pracownicy">Nasza Kadra</Link>
              </p>
            </div>
            <div className="menu-link-dropdown-content-item">
              <p>
                <Link to="/offert">Cennik</Link>
              </p>
            </div>
            <div className="menu-link-dropdown-content-item">
              <p>
                <Link to="/about">FAQ</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="menu-category">
        <Link to={'/about'}>Apteka</Link>
      </div> */}
      <div className="menu-category">
        <p>
          Info{' '}
          <svg
            viewBox="0 1 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.313 1L9.5 11 1.687 1"
              stroke="#24679A"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </p>
        <div className="menu-link-dropdown">
          <div className="menu-link-dropdown-content">
            <div className="menu-link-dropdown-content-item">
              <p>
                <Link to="/contact">Kontakt</Link>
              </p>
            </div>
            <div className="menu-link-dropdown-content-item">
              <p>
                <Link to="/privacy">Polityka prywatności</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuCategories;
